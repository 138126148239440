import ModelForm from '@/components/ModelForm/ModelForm.vue'
import ErrorMixin from '@/mixins/error.mixin'

export default {
  props: ['id'],
  mixins: [ErrorMixin],
  components: {
    ModelForm
  },
  data() {
    return {
      name: null
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: 'Models',
          disabled: false,
          to: { name: 'list-models' },
          exact: true
        },
        { text: `Edit ${this.name}` }
      ]
    } 
  },
  methods: {
    setName(v) {
      this.name = v
    }
  },
  created() {
    if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
      this.renderError(404)
    }
  }
}
